import React, { useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Loading from './reusables/Loading';

function FileUpload({ update }) {
  const [loading, setLoading] = useState(false);

  const uploadEndPoint = '/assets/upload_image/';
  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('image', file);

    // eslint-disable-next-line no-return-await
    const req = await axios.post(uploadEndPoint, formData);
    const res = await req.data.url;
    return res;
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const file = e.target.files[0];
    try {
      const url = await uploadFile(file);
      update('imageUrl', url);
    } catch (err) {
      alert(err);
    }
    setLoading(false);
  };

  return (
    <form className={`fileUpload${loading ? '--loading' : ''}`}>
      <Loading />
      <input
        type="file"
        className="form-upload"
        onChange={handleSubmit}
        disabled={loading}
      />
    </form>
  );
}

FileUpload.propTypes = {
  update: PropTypes.func.isRequired,
};

export default FileUpload;
