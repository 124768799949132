import React, { useState, useEffect } from 'react';
import axios from 'axios';
import List from './reusables/List';
import DateFilter from './reusables/DateFilter';
import CustomPagination from './reusables/CustomPagination';

import AddReturnsModal from './AddReturnsModal';
import Loading from './reusables/Loading';
import FileDownload from './FileDownload';

const Returns = () => {
  const [filter, setFilter] = useState({
    startDate: null,
    endDate: null,
  });
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState({
    current: 1,
    total: 10,
  });

  const [returns, setReturns] = useState([]);
  const [show, setShow] = useState(false);

  const handleSetFilter = (key, value) => {
    const cloned = { ...filter };
    cloned[key] = value;
    setFilter(cloned);
  };

  const fetchReturns = async () => {
    const { startDate, endDate } = filter;
    setLoading(true);
    const req = await axios.get('/returns/items/', {
      params: {
        page: page.current,
        start_date: startDate,
        end_date: endDate,
      },
    });
    const res = await req.data;
    const { result, total_pages: totalPages } = res;

    // Headers For Table
    //  "Date Received",
    //  "Supplier",
    //  "Material Description",
    //  "QTY",
    //  "Returned To",
    //  "Date Returned",
    //  "Remarks"

    const toSet = result.map((resFoo) => {
      const {
        id, count, delivery_item: deliveryItem, remarks, _return,
      } = resFoo;
      return {
        ID: id,
        'Date Received': deliveryItem.created_at,
        Supplier: _return.supplier.name,
        'Material Description': _return.description,
        QTY: count,
        'Returned To': _return.supplier.name,
        'Date Returned': _return.created_at,
        Status: _return.status,
        Remarks: remarks,
      };
    });
    setReturns(toSet);

    const cloned = { ...page };
    cloned.total = totalPages;
    setPage(cloned);
    setLoading(false);
  };

  const showTable = () => {
    if (loading) {
      return <Loading />;
    }
    if (returns[0]) {
      return (
        <div>
          <List
            data={returns}
            type="returns"
            deleteEndpoints={[
              '/returns/',
              '/returns/items/',
            ]}
            fetchItems={fetchReturns}
          />
          <CustomPagination
            page={page}
            setPage={setPage}
          />
        </div>
      );
    }
    return (
      <div className="issuance__merchantLabel">
        <p>
          No Results
        </p>
      </div>
    );
  };

  useEffect(() => {
    fetchReturns();
  }, [page.current, filter]);

  const handleClose = () => setShow(false);
  const exportURL = '/returns/items/export/';
  return (
    <div className="returns returnsModal">
      <div className="customButton">
        <DateFilter
          handleOnChange={handleSetFilter}
          startDate={filter.startDate}
          endDate={filter.endDate}
        />
        <FileDownload url={exportURL} />
        <AddReturnsModal
          show={show}
          onHide={handleClose}
          fetchReturns={fetchReturns}
        />
      </div>
      { showTable() }
    </div>
  );
};

export default Returns;
