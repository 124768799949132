/* eslint-disable react/jsx-no-constructed-context-values */
import axios from 'axios';
import './App.scss';
import React, { useEffect, useState, useRef } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import { ThreeDots } from 'react-loading-icons';
import {
  getAuth, onAuthStateChanged, signInWithPopup, GoogleAuthProvider,
} from 'firebase/auth';
import firebase from './components/reusables/Firebase';
import {
  fetchCategories,
  fetchSppUsers,
  fetchMerchants,
  fetchSuppliers,
} from './assets/helpers';
import AppContext from './components/AppContext';
import NavBar from './components/NavBar';
import Issuance from './components/Issuance';
import Inventory from './components/Inventory';
import Delivery from './components/Delivery';
import Returns from './components/Returns';

const AuthProvider = ({ children, fetchingUser }) => {
  let toReturn;

  if (fetchingUser) {
    toReturn = (
      <div className="loading-page">
        <ThreeDots fill="#EE4D2D" />
      </div>
    );
  } else {
    toReturn = children;
  }

  return toReturn;
};

function App() {
  const [user, setUser] = useState({});
  const [fetchingUser, setFetchingUser] = useState(true);
  const [sppUsers, setSppUsers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [merchants, setMerchants] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const provider = new GoogleAuthProvider(firebase);
  const auth = getAuth(firebase);

  const setRefresh = useRef(null);
  const userRef = useRef(null);

  const signInGoogle = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        setUser(result.user);
      }).catch((error) => {
        const errorMessage = error.message;
        alert(errorMessage);
      });
  };

  const refetchSuppliers = async () => {
    setSuppliers(await fetchSuppliers());
  };

  const refetchMerchants = async () => {
    setMerchants(await fetchMerchants());
  };

  useEffect(() => {
    onAuthStateChanged(auth, (signedUser) => {
      if (!signedUser) signInGoogle();
      else {
        signedUser.getIdToken().then((idToken) => {
          setUser({
            ...signedUser,
            idToken,
          });

          // if any interval exists stop it before loading new interval
          if (setRefresh.current) {
            clearInterval(setRefresh.current);
          }
          userRef.current = signedUser;
          // refresh every 5 mins when logged in
          setRefresh.current = setInterval(() => {
            if (userRef.current) {
              console.log('Token Refreshed');
              userRef.current.getIdToken(true); // token refreshed
              axios.defaults.headers.common.Authorization = `Bearer ${userRef.current.accessToken}`;
            }
          }, 300000);
        });
      }
    });

    return () => {
      if (setRefresh.current) {
        clearInterval(setRefresh.current);
      }
    };
  }, []);

  useEffect(async () => {
    if ('emailVerified' in user) {
      setFetchingUser(false);
      axios.defaults.headers.common.Authorization = `Bearer ${userRef.current.accessToken}`;
      setCategories(await fetchCategories());
      setSppUsers(await fetchSppUsers());
      refetchMerchants();
      refetchSuppliers();
    }
  }, [user]);

  return (
    <div className="sppInventory">
      {user && (
      <AppContext.Provider value={{
        user,
        sppUsers,
        categories,
        merchants,
        suppliers,
        refetchSuppliers,
        refetchMerchants,
      }}
      >
        <AuthProvider
          fetchingUser={fetchingUser}
        >
          <BrowserRouter>
            <NavBar />
            <section className="sppInventory__content">
              <Routes>
                <Route path="/" element={<Inventory />} />
                <Route path="/inventory" element={<Inventory />} />
                <Route path="/issuance" element={<Issuance />}>
                  <Route path=":id" element={<Issuance />} />
                </Route>
                <Route path="/delivery" element={<Delivery />}>
                  <Route path=":id" element={<Delivery />} />
                </Route>
                <Route path="/returns" element={<Returns />}>
                  <Route path=":id" element={<Returns />} />
                </Route>
              </Routes>
            </section>
          </BrowserRouter>
        </AuthProvider>
      </AppContext.Provider>
      )}
    </div>
  );
}

export default App;
