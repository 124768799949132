import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Logo from '../assets/images/general/logo.png';

const NavBar = () => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <section className="navBar">
      <div className="d-flex  align-items-center mb-4">
        <div className="logo-container mr-xs">
          <img src={Logo} alt="Shopee-Logo" />
        </div>
        <h1> SPP Inventory</h1>
      </div>
      <nav>
        <NavLink
          className={pathname === '/' && 'active'}
          to="/inventory"
        >
          Inventory
        </NavLink>
        <NavLink to="/issuance">Issuance </NavLink>
        <NavLink to="/delivery"> Delivery</NavLink>
        <NavLink to="/returns">Returns </NavLink>
      </nav>
    </section>
  );
};
export default NavBar;
