/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Table, Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import Loading from './Loading';
import ViewAssetModal from '../ViewAssetModal';
// import DeleteModal from './DeleteModal';
import CustomPagination from './CustomPagination';

import Shopee from '../../assets/images/general/logo.png';

function InventoryCard({ category }) {
  const [page, setPage] = useState({
    current: 1,
    total: 10,
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [assetToView, setAssetToView] = useState({});

  const handleClose = () => {
    setAssetToView({});
  };
  const iniLoaded = useRef(false);

  const fetchInventory = (scrollTo = false) => {
    setIsLoaded(false);
    axios.get('/assets', {
      params: {
        category: category?.id,
        page: page.current,
      },
    }).then(({ data }) => {
      setItems(data.result);
      setIsLoaded(true);
      iniLoaded.current = true;

      setPage((prevState) => {
        return {
          current: prevState.current,
          total: data.total_pages,
        };
      });

      // scrollto only when page state changes
      if (scrollTo) {
        setTimeout(() => {
          document.querySelector(`#invcard-cat-${category?.id}`).scrollIntoView();
        }, 100);
      }
    });
  };

  useEffect(() => {
    if (category && !isLoaded) {
      fetchInventory();
    }
  }, [category]);

  useEffect(() => {
    if (iniLoaded.current) {
      fetchInventory(true);
    }
  }, [page.current]);

  const renderTbody = () => {
    return (
      items.map((item) => {
        const {
          // id,
          name,
          actual_count: actualCount,
          deliveries_total: totalDeliveries,
          image_url: imageUrl,
        } = item;

        return (
          <tr>
            <td className="inv-delete">
              {/* <div>
                <DeleteModal
                  endpoint="/assets/"
                  id={id}
                  fetchItems={fetchInventory}
                />
              </div>
              Commented out 6/01/2022
              By John Bacabac - SPPHBI-30028
              */}
            </td>
            <td
              className="inv-name"
              onClick={() => setAssetToView(item)}
              aria-hidden="true"
            >
              <div>
                <img
                  alt="item-img"
                  src={imageUrl || Shopee}
                />
              </div>
              <span>{ name }</span>
            </td>
            <td className="inv-count">
              <span>{actualCount}</span>
            </td>
            <td className="inv-count">
              <span>{totalDeliveries}</span>
            </td>
          </tr>
        );
      })
    );
  };

  const showAssets = () => {
    if (items[0]) {
      return (
        <div>
          <div className="inventoryCard__content">
            <Table>
              <thead>
                <tr>
                  <td />
                  <td>Assets</td>
                  <td>Actual Count</td>
                  <td>Total Deliveries</td>
                </tr>
              </thead>
              <tbody>
                {!isLoaded
                  ? <td colSpan="4"><Loading /></td>
                  : renderTbody()}
              </tbody>
            </Table>
          </div>
          <Modal show={assetToView.id} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title>Asset Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ViewAssetModal
                asset={assetToView}
                category={category}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleClose}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          <CustomPagination
            page={page}
            setPage={setPage}
          />
        </div>
      );
    }
    if (!isLoaded) {
      return <Loading />;
    }
    return (
      <div className="issuance__merchantLabel">
        <p>
          No Results
        </p>
      </div>
    );
  };

  return (
    <section
      className="inventoryCard"
      id={`invcard-cat-${category?.id}`}
    >
      <h5>{category.name}</h5>
      { showAssets() }
    </section>
  );
}
export default InventoryCard;

InventoryCard.propTypes = {
  category: PropTypes.object.isRequired,
};
