import {
  React, useEffect, useState, useContext, useRef,
} from 'react';
import {
  useParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import moment from 'moment';
import PropType from 'prop-types';
import {
  Button, Modal, Container, Row, Col, Form,
} from 'react-bootstrap';
import axios from 'axios';
import Select from 'react-select';

import AppContext from './AppContext';
import FilterSearch from './reusables/FilterSearch';
import Loading from './reusables/Loading';

import { ReactComponent as Plus } from '../assets/images/general/plus.svg';
import { ReactComponent as Edit } from '../assets/images/general/Edit.svg';
import { ReactComponent as Delete } from '../assets/images/general/Delete.svg';

function AddIssuanceModal({ toEdit, fetchIssuances }) {
  const errorCount = useRef({});

  const [searchParams] = useSearchParams();
  const fromEdit = searchParams.get('edit') === 'true';
  const createSuccess = searchParams.get('create-success') === 'true';
  const editSuccess = searchParams.get('edit-success') === 'true';

  const params = useParams();
  const navigate = useNavigate();

  const { merchants: merchs, sppUsers } = useContext(AppContext);
  const merchants = merchs.map((merch) => {
    return {
      value: merch.id,
      label: merch.name,
    };
  });
  const users = sppUsers.map((merch) => {
    return {
      value: merch.id,
      label: merch.name,
    };
  });
  const iniIssuanceState = {
    date: moment().format('YYYY-MM-DD'),
    merchant: { value: 0, label: '' },
    description: '',
    sppUser: { value: 0, label: '' },
    storesCount: 0,
  };

  const [inputListRefresher, setInputListRefresh] = useState(0);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  // saved asset items
  const [inputList, setInputList] = useState([{ asset: {}, count: 0 }]);
  const [itemsToDelete, setItemsToDelete] = useState([]);
  const [issuance, setIssuance] = useState(iniIssuanceState);

  const resetStates = () => {
    setInputList([{ asset: {}, count: 0 }]);
    setIssuance(iniIssuanceState);
  };

  const handleClose = (/* e, redirectTo = '/issuance' */) => {
    // I spent too long on dis but for some reason need timeout for dis to work
    //  will figure out why why soon
    /* setTimeout(() => {
      setTimeout(() => {
        navigate(redirectTo);
      }, 100);
      navigate('/issuance');
    }, 100);
    // Commented out 6/01/2022
    // By John Bacabac - SPPHBI-29701
    */
    setTimeout(() => {
      navigate('/issuance');
    }, 100);
    setShow(false);
    resetStates();
  };
  const handleShow = () => setShow(true);

  // set issuance if url param present
  // eslint-disable-next-line consistent-return
  useEffect(async () => {
    const { id } = params;

    // need this condition to handle showing of modal on different cases
    if (id && toEdit <= 0 && !fromEdit) {
      // when going to /returns/{id}
      /* proceed */
    } else if (id && toEdit === parseInt(id, 10) && fromEdit) {
      // when going to /returns/{id}?edit=true
      /* proceed */
    } else return false;

    handleShow();
    const issReq = await axios.get(`/issuances/${id}`);
    const issRes = await issReq.data;
    const {
      result: {
        id: issId,
        created_at: createdAt,
        description,
        merchant,
        spp_user: sppUser,
        store_count: storesCount,
        issuanceitem_set: issuanceItems,
      },
    } = issRes;

    setIssuance({
      date: moment(createdAt).format('YYYY-MM-DD'),
      merchant: { value: merchant.id, label: merchant.name },
      description,
      sppUser: { value: sppUser.id, label: sppUser.name },
      storesCount,
      id: issId,
    });

    setInputList(issuanceItems.map((issItem) => {
      return {
        asset: {
          value: issItem.asset_item.id,
          label: issItem.asset_item.name,
          count: issItem.asset_item.actual_count,
          resultItem: { issItemId: issItem.id, ...issItem }, // need this for updating
        },
        count: issItem.count,
      };
    }));
  }, [params.id]);

  const handleSubmit = async () => {
    const { id } = params;
    setLoading(true);
    const {
      date,
      merchant,
      description,
      sppUser,
      storesCount,
    } = issuance;

    const itemsSet = inputList.map((input) => {
      const { asset, count } = input;
      return {
        asset_item: asset.value,
        count,
      };
    });

    const method = id ? 'put' : 'post';
    const issReq = await axios[method](`/issuances/${id || ''}`, {
      ...(date && { created_at: date }),
      merchant: merchant.value,
      store_count: storesCount,
      description,
      spp_user: sppUser.value,
      ...(method === 'post' && { issuanceitem_set: itemsSet }),
    });
    const issRes = issReq.data;

    // update issuance items
    if (method === 'put') {
      const promisesPUT = [];
      const promisesDELETE = [];

      const itemsToPost = [];
      inputList.forEach(({
        asset: { resultItem, value: assetItemId },
        count,
      }) => {
        const issuanceItemId = resultItem?.issItemId;

        // PUT if item contains issuance id (meaning it's already saved in db)
        if (issuanceItemId) {
          promisesPUT.push(axios.put(`/issuances/items/${issuanceItemId}`, {
            asset_item: assetItemId,
            count,
          }));
        } else {
          // POST if item doesn't have issuance id yet
          itemsToPost.push({
            issuance: issuance.id,
            asset_item: assetItemId,
            count,
          });
        }
      });

      // DELETE issuance items
      if (itemsToDelete?.length > 0) {
        itemsToDelete.forEach((itemToDelete) => {
          const { resultItem } = itemToDelete.asset;
          if (resultItem) {
            promisesDELETE.push(axios.delete(`/issuances/items/${resultItem?.issItemId}`));
          }
        });
      }

      try {
        await Promise.all(promisesPUT);
        await Promise.all(promisesPUT);
        await axios.post('/issuances/items/', itemsToPost);
      } catch (e) {
        alert(e);
      }
    }

    setLoading(false);
    resetStates();
    fetchIssuances();
    // show only success when creating not editing
    handleClose(
      null,
      `/issuance/${issRes.id}${!params?.id ? '?create-success=true' : '?edit-success=true'}`,
    );
  };

  const updateIssuance = (value, attr) => {
    const cloned = { ...issuance };
    cloned[attr] = value;
    setIssuance(cloned);
  };

  /** handle input change
   * e can either be a dom object from the count input or an obect from the search
   */
  const handleInputChange = (e, index, attr) => {
    let value = e.target?.value || e;
    const list = [...inputList];
    // this contains the issuance item if it's already saved in db
    if (attr === 'asset') {
      const resultItem = list[index].asset?.resultItem;
      if (resultItem) value.resultItem = resultItem;
    } else if (attr === 'count') {
      value = parseInt(value, 10);
    }

    list[index][attr] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    delete errorCount.current[index];
    const list = [...inputList];
    const delClone = [...itemsToDelete];

    setItemsToDelete([...delClone, list[index]]);
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { asset: {}, count: 0 }]);
  };

  const disableButton = (
    !issuance.merchant.value
      || !issuance.sppUser.value
      || !(inputList.every((item) => item.asset.value && item.count > 0))
      || issuance.storesCount <= 0
      || loading
      || !issuance.date
      || Object.keys(errorCount.current).length > 0
  );

  let spanMessage = '';
  if (editSuccess) spanMessage = 'Edited Successfully';
  else if (createSuccess) spanMessage = 'Created Successfully';

  return (
    <div className="addDeliveryModal">
      { toEdit <= -1
        ? (
          <Button onClick={handleShow}>
            {' '}
            <Plus /> Add Issuance{' '}
          </Button>
        )
        : (
          <div className="actionButton--edit" onClick={handleShow} aria-hidden="true">
            <Edit />
          </div>
        )}
      <Modal show={show} onHide={handleClose} scrollable>
        <Modal.Header>
          <Modal.Title>
            {!params?.id ? 'Add' : 'Edit'} Issuance Report &nbsp;
            <span className="success">
              { spanMessage }
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Container>
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="date">
                    <Form.Label>Date **</Form.Label>
                    <Form.Control
                      type="date"
                      id="startDate"
                      onChange={({ target }) => {
                        updateIssuance(target.value, 'date');
                      }}
                      value={issuance.date}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="issuanceMerchant">
                    <Form.Label>Merchant Name **</Form.Label>
                    <Select
                      options={merchants}
                      onChange={(e) => {
                        updateIssuance(e, 'merchant');
                      }}
                      value={issuance.merchant}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                {inputList.map((x, i) => {
                  const maxQty = x.asset?.count || x.count;
                  return (
                    <>
                      <Col xs={7} className="mb-3">
                        <Form.Group controlId="material">
                          <Form.Label>Assets **</Form.Label>
                          <FilterSearch
                            key={`${inputListRefresher}-${i + 1}`}
                            handleChange={(value, index, toUpdate) => {
                              // trigger recall api for search to avoid dupes
                              setInputListRefresh(inputListRefresher + 1);
                              handleInputChange(value, index, toUpdate);
                            }}
                            index={i}
                            selected={inputList[i].asset}
                            valuesToHide={
                              inputList.map(({ asset }) => asset?.value)
                            }
                            filters={{
                              removeNegative: true,
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col className="mb-3">
                        <Form.Group controlId="deliveryCount">
                          <Form.Label>Qty -
                            <small>{`Max: ${typeof maxQty === 'number' ? maxQty : ''}`}</small>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            id={`actualCount-${x.asset?.value}`}
                            onChange={(e) => {
                              handleInputChange(e, i, 'count');

                              const countToCompare = x.asset?.count || x.count;
                              if (parseInt(inputList[i].count, 10) > parseInt(countToCompare, 10)) {
                                errorCount.current[i] = true;
                              } else if (errorCount.current[i]) {
                                delete errorCount.current[i];
                              }
                            }}
                            value={inputList[i].count}
                          />
                        </Form.Group>
                      </Col>
                      <Col className="mb-3">
                        <Form.Label> </Form.Label>
                        <div className="actionButton">
                          {inputList.length - 1 === i && (
                          <div
                            className="actionButton--add mr-5"
                            onClick={handleAddClick}
                            aria-hidden="true"
                          >
                            <Plus />
                          </div>
                          )}
                          {inputList.length !== 1 && (
                          <div
                            className="actionButton--delete"
                            onClick={() => handleRemoveClick(i)}
                            aria-hidden="true"
                          >
                            <Delete />
                          </div>
                          )}
                        </div>
                      </Col>
                    </>
                  );
                })}
              </Row>
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="deliveryDescription">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      onChange={({ target }) => {
                        updateIssuance(target.value, 'description');
                      }}
                      value={issuance.description}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Endorsed to SPP **</Form.Label>
                    <Select
                      options={users}
                      onChange={(e) => {
                        updateIssuance(e, 'sppUser');
                      }}
                      value={issuance.sppUser}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="issuanceStores">
                    <Form.Label>No. of Stores **</Form.Label>
                    <Form.Control
                      type="number"
                      onChange={({ target }) => {
                        updateIssuance(target.value, 'storesCount');
                      }}
                      value={issuance.storesCount}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="white"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={disableButton}
          >
            { loading ? <Loading /> : 'Submit' }
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

AddIssuanceModal.propTypes = {
  toEdit: PropType.number,
  fetchIssuances: PropType.func.isRequired,
};

AddIssuanceModal.defaultProps = {
  toEdit: -1,
};

export default AddIssuanceModal;
