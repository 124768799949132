import { React, useState } from 'react';
import {
  Button, Modal,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import axios from 'axios';

import { ReactComponent as Delete } from '../../assets/images/general/Delete.svg';
import Loading from './Loading';

const DeleteModal = ({
  fetchItems,
  endpoint,
  endpoints,
  id,
  callback,
}) => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleDelete = () => {
    setLoading(true);
    const uid = id || '';
    if (endpoint && endpoint !== '') {
      axios.delete(`${endpoint}${uid}`).then(() => {
        setLoading(false);
        handleClose();
        fetchItems();
        if (callback) { callback(); }
      });
    } else if (endpoints && endpoints.length > 0) {
      // endpoints can be array
      endpoints.forEach((pointedEnd) => {
        axios.delete(`${pointedEnd}${uid}`).then(() => {
          setLoading(false);
          handleClose();
          fetchItems();
          if (callback) { callback(); }
        });
      });
    }
  };

  return (
    <div className="addIssuanceModal">
      <div className="actionButton--delete" onClick={handleShow} aria-hidden="true">
        <Delete />
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        scrollable
      >
        <Modal.Header>
          <Modal.Title>Are you sure you want to delete this item?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="white"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleDelete}
            disabled={loading}
          >
            {loading ? <Loading /> : 'Delete'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

DeleteModal.propTypes = {
  fetchItems: PropTypes.func,
  id: PropTypes.number,
  endpoint: PropTypes.string.isRequired,
  endpoints: PropTypes.array,
  callback: PropTypes.func,
};

DeleteModal.defaultProps = {
  fetchItems: () => {},
  id: -1,
  endpoints: [],
  callback: null,
};

export default DeleteModal;
