import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import AddDeliveryModal from '../AddDeliveryModal';
import DeleteModal from './DeleteModal';

const DeliveryCard = ({ item, fetchDeliveries }) => {
  const {
    created_at: createdAt,
    count_date_time: countDateTime,
    description,
    deliveryitem_set: deliveryItems,
    supplier,
  } = item;

  const navigate = useNavigate();

  return (
    <section className="deliveryCard">
      <div className="d-flex justify-content-end align-items-center">
        <div className="actionButton">
          <div
            role="button"
            tabIndex={-1}
            onClick={() => {
              navigate(`/delivery/${item.id}?edit=true`);
            }}
          >
            <AddDeliveryModal
              toEdit={item.id}
              fetchDeliveries={fetchDeliveries}
            />
          </div>
          <DeleteModal
            endpoint="/deliveries/"
            id={item.id}
            fetchItems={fetchDeliveries}
          />
        </div>
      </div>
      <div className="deliveryCard__content">
        <div>
          <p>
            <b>Date Delivered</b>: { createdAt }
          </p>
          <p>
            <b>Date Count</b>: { countDateTime }
          </p>
          <p>
            <b>Supplier</b>: { supplier?.name }
          </p>
          <p className="deliveryCard__description">
            <b>Material Description</b>: { description }
          </p>
        </div>
        <div>
          <table>
            {
              deliveryItems.map((deliveryItem) => {
                const {
                  asset_item: assetItem,
                  count,
                  return_item_total: returnCount,
                } = deliveryItem;

                return (
                  <tr>
                    <td>{ assetItem.name }</td>
                    <td>{ count }</td>
                    <td>{ returnCount} Damage</td>
                  </tr>
                );
              })
            }
          </table>
        </div>
      </div>
    </section>
  );
};

export default DeliveryCard;

DeliveryCard.propTypes = {
  item: PropTypes.object.isRequired,
  fetchDeliveries: PropTypes.func.isRequired,
};
