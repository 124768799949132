import { React } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Form,
} from 'react-bootstrap';

const ViewAssetModal = ({ category, asset }) => {
  const {
    name: itemName, description,
    actual_count: actualCount,
    deliveries_total: totalDeliveries,
    image_url: imageUrl,
  } = asset;
  const { name: catName } = category;
  return (
    <div className="viewAssetModal">
      <Form>
        <Container>
          <Row className="mt-4">
            <Col>
              <Form.Group className="mb-3" controlId="assetTitle">
                <h5 className="mb-4"> {itemName}</h5>
                <p className="mb-3"><b>Type:</b> {catName} </p>
                <p className="line-break">
                  <b> Description: </b> {description}
                </p>
                <p> <b>Actual Count:</b> {actualCount}</p>
                <p><b>Total Deliveries:</b> {totalDeliveries} </p>
              </Form.Group>
            </Col>
            <Col>
              <div className="form-imagecontainer">
                <img src={imageUrl} alt="inventory-item" />
              </div>
            </Col>
          </Row>
        </Container>
      </Form>
    </div>
  );
};

ViewAssetModal.propTypes = {
  asset: PropTypes.object.isRequired,
  category: PropTypes.object.isRequired,

};

export default ViewAssetModal;
