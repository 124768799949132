import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import axios from 'axios';
import Loading from './reusables/Loading';

async function getFileName(response) {
  const curDate = new Date().toISOString().split('T')[0];
  const fileNameHeader = 'content-disposition';
  const patternToRemove = 'attachment; filename=';
  const fileName = response.headers[fileNameHeader].replace(patternToRemove, '').split('.');

  return `${fileName[0]}_${curDate}.${fileName[1]}`;
}

function FileDownload({ url }) {
  const [loading, setLoading] = useState(false);
  const fileDownload = async () => {
    const response = await axios({
      url,
      method: 'GET',
      responseType: 'blob',
    });

    const fileName = await getFileName(response);
    const fileURL = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');

    link.href = fileURL;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  };

  const handleDownload = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      await fileDownload(url);
    } catch (err) {
      alert(err);
    }
    setLoading(false);
  };

  return (
    <Button className="export white" variant="secondary" onClick={handleDownload} disabled={loading}>
      { loading ? <Loading /> : 'Export' }
    </Button>
  );
}

FileDownload.propTypes = {
  url: PropTypes.string.isRequired,
};

export default FileDownload;
