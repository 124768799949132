import React, {
  useEffect,
  useState, useContext, useRef,
} from 'react';
import {
  Button, Modal, Container, Row, Form,
} from 'react-bootstrap';
import axios from 'axios';

import AppContext from './AppContext';
import MerchantList from './reusables/MerchantList';
import { ReactComponent as Plus } from '../assets/images/general/plus.svg';
import { ReactComponent as Search } from '../assets/images/general/Search.svg';

const ManageMerchantsModal = () => {
  const { refetchMerchants } = useContext(AppContext);
  const [toSearch, setToSearch] = useState('');
  const [show, setShow] = useState(false);

  const merchantName = useRef('');
  const inputEl = useRef(null);
  const submitBtn = useRef(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (show) { submitBtn.current.disabled = true; }
  }, [show]);

  const handleSubmit = () => {
    submitBtn.current.disabled = true;
    axios.post('/issuances/merchants/', [{
      name: merchantName.current,
    }]).then(() => {
      merchantName.current = '';
      inputEl.current.value = '';
      setToSearch('');
      refetchMerchants();
    });
  };

  return (
    <div className="manageMerchants">
      <Button onClick={handleShow} className="btn-outline">
        Manage Merchants{' '}
      </Button>
      <Modal show={show} onHide={handleClose} scrollable>
        <Modal.Header>
          <Modal.Title>Manage Merchants</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Container>
              <Row className="mb-5 px-2">
                <Form.Control
                  type="text"
                  placeholder="Search"
                  onChange={({ target }) => {
                    setToSearch(target.value);
                  }}
                />
                <Search />
              </Row>
              <Row className="mb-3">
                <div className="d-flex justify-content-between">
                  <div className="mr-xs form__input-container">
                    {' '}
                    <Form.Control
                      type="text"
                      placeholder="Merchant Name"
                      ref={inputEl}
                      onChange={({ target }) => {
                        merchantName.current = target.value;
                        if (target.value === '') submitBtn.current.disabled = true;
                        else submitBtn.current.disabled = false;
                      }}
                    />
                  </div>
                  <Button
                    onClick={handleSubmit}
                    ref={submitBtn}
                  >
                    <Plus /> &nbsp; Add New
                  </Button>
                </div>
              </Row>
              <MerchantList
                toSearch={toSearch.toLowerCase()}
              />
            </Container>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ManageMerchantsModal;
