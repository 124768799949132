/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import PropTypes from 'prop-types';
import axios from 'axios';
import AddIssuanceModal from '../AddIssuanceModal';
import DeleteModal from './DeleteModal';
import { statuses } from '../../assets/helpers';

function IssuanceCard({
  item,
  fetchIssuances,
  setMerchantFilter,
}) {
  const navigate = useNavigate();
  const {
    created_at: createdAt,
    description,
    issuanceitem_set: issuanceItems,
    spp_user: sppUser,
    store_count: storeCount,
    merchant,
    status,
    id,
  } = item;

  const changeStatus = (statusInput) => {
    axios.put(`/issuances/${id}`, {
      status: statusInput,
    }).then(() => {
      fetchIssuances();
    });
  };

  return (
    <section className="issuanceCard">
      <div className="d-flex justify-content-between align-items-center">
        <a
          onClick={() => {
            setMerchantFilter(merchant);
          }}
          href="#"
          role="button"
        >
          {merchant.name}
        </a>
        <div className="actionButton">
          <div
            role="button"
            tabIndex={-1}
            onClick={() => {
              navigate(`/issuance/${item.id}?edit=true`);
            }}
          >
            <AddIssuanceModal
              toEdit={item.id}
              fetchIssuances={fetchIssuances}
            />
          </div>
          <DeleteModal
            endpoint="/issuances/"
            fetchItems={fetchIssuances}
            id={item.id}
          />
        </div>
      </div>
      <div className="issuanceCard__content">
        <div>
          <p>
            <b>Date Endorsed</b>: { createdAt }
          </p>
          <p>
            <b>Merchant Name</b>: { merchant.name }
          </p>
          <p className="issuanceCard__description">
            <b>Material Description</b>: { description }
          </p>
          <p>
            <b>Endorsed to</b>: { sppUser?.name }
          </p>
          <p>
            <b>No. of Stores</b>: { storeCount }
          </p>
        </div>
        <div>
          {
            issuanceItems?.map((issuanceItem) => {
              const {
                count,
                asset_item: assetItem,
              } = issuanceItem;
              return (
                <p>
                  <b> { assetItem?.name }</b>: { count }
                </p>
              );
            })
          }
        </div>
      </div>
      <DropdownButton
        className="issuanceCard__status"
        title={status}
      >
        {Object.keys(statuses).map((statusKey) => {
          const label = statuses[statusKey];
          return (
            <Dropdown.Item
              onClick={() => { changeStatus(statusKey); }}
            >
              {label}
            </Dropdown.Item>
          );
        })}
      </DropdownButton>
    </section>
  );
}

export default IssuanceCard;

IssuanceCard.propTypes = {
  item: PropTypes.object.isRequired,
  fetchIssuances: PropTypes.func.isRequired,
  setMerchantFilter: PropTypes.func.isRequired,
};
