import React, { useState, useContext } from 'react';
import {
  Button, Modal, Container, Row, Col, Form,
} from 'react-bootstrap';
import axios from 'axios';

import { ReactComponent as Plus } from '../assets/images/general/plus.svg';
import Loading from './reusables/Loading';
import FileUpload from './FileUpload';
import AppContext from './AppContext';

const AddInventoryModal = () => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const { categories } = useContext(AppContext);

  const [inventory, setInventory] = useState({
    name: '',
    category: 0, // id of category
    description: '',
    beginningCount: 0,
    imageUrl: '',
  });

  const resetStates = () => {
    setInventory({
      name: '',
      category: 0, // id of category
      description: '',
      beginningCount: 0,
      imageUrl: '',
    });
  };

  const handleClose = () => {
    setShow(false);
    resetStates();
  };
  const handleShow = () => setShow(true);

  const updateInventory = (attr, value) => {
    const cloned = { ...inventory };
    cloned[attr] = value;
    setInventory(cloned);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const {
      name,
      category,
      description,
      beginningCount,
      imageUrl,
    } = inventory;

    await axios.post('/assets/', {
      asset_item_category: category,
      name,
      description,
      image_url: imageUrl,
      beginning_count: beginningCount,
    });

    setLoading(false);
    handleClose();
    window.location.reload();
  };

  const disableSubmit = (
    inventory.name.trim() === ''
      || inventory.category <= 0
      || loading
  );

  return (
    <div className="addInventoryModal">
      <Button onClick={handleShow}>
        <Plus /> Add Asset{' '}
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Add Asset</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Container>
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="inventoryName">
                    <Form.Label>Name **</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={({ target }) => {
                        updateInventory('name', target.value);
                      }}
                      value={inventory.name}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="inventoryType">
                    <Form.Label>Category **</Form.Label>
                    <Form.Select
                      onChange={({ target }) => {
                        updateInventory('category', parseInt(target.value, 10));
                      }}
                    >
                      <option selected={inventory.category <= 0} disabled>Choose Category</option>
                      { categories.map((category) => (
                        <option
                          value={category.id}
                          selected={category.id === inventory.category}
                        >
                          {category.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="inventoryDescription">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      onChange={({ target }) => {
                        updateInventory('description', target.value);
                      }}
                      value={inventory.description}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col>
                  <Form.Group controlId="inventoryCount">
                    <Form.Label>Beginning Count</Form.Label>
                    <Form.Control
                      type="number"
                      onChange={({ target }) => {
                        updateInventory('beginningCount', parseInt(target.value, 10));
                      }}
                      value={inventory.beginningCount}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  { /* Empty for spacing */ }
                </Col>
              </Row>
              <Row>
                <FileUpload
                  update={updateInventory}
                />
              </Row>
            </Container>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="white"
            onClick={() => { handleClose(); }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={disableSubmit}
          >
            { loading ? <Loading /> : 'Submit' }
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddInventoryModal;
