import {
  React, useEffect, useState, useContext,
} from 'react';
import {
  Button, Modal, Container, Row, Col, Form,
} from 'react-bootstrap';
import PropType from 'prop-types';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import Select from 'react-select';
import axios from 'axios';
import AppContext from './AppContext';
import FilterSearch from './reusables/FilterSearch';
import Loading from './reusables/Loading';

import { ReactComponent as Plus } from '../assets/images/general/plus.svg';
import { ReactComponent as Edit } from '../assets/images/general/Edit.svg';

const AddReturnsModal = ({ toEdit, fetchReturns }) => {
  const [searchParams] = useSearchParams();
  const fromEdit = searchParams.get('edit') === 'true';
  const createSuccess = searchParams.get('create-success') === 'true';
  const editSuccess = searchParams.get('edit-success') === 'true';

  const params = useParams();
  const navigate = useNavigate();

  const { suppliers: supps } = useContext(AppContext);
  const suppliers = supps.map((supp) => {
    return {
      value: supp.id,
      label: supp.name,
    };
  });

  const iniReturnState = {
    dateReturned: moment().format('YYYY-MM-DD'),
    deliveryItem: { value: 0, label: '' },
    description: '',
    quantity: 0, // qty of delivery item
    remarks: '',
    supplier: { value: 0, label: '' },
    status: 'pending',
  };

  const [loading, setLoading] = useState(false);
  const [returnState, setReturnState] = useState(iniReturnState);
  const [show, setShow] = useState(false);

  const resetStates = () => {
    setReturnState({
      dateReturned: '',
      deliveryItem: { value: 0, label: '' },
      description: '',
      quantity: 0, // qty of delivery item
      remarks: '',
      supplier: { value: 0, label: '' },
      status: 'pending',
    });
  };

  const handleClose = (/* redirectTo = '/returns' */) => {
    /* setTimeout(() => {
      // nav to /returns first then to /returns/<id>
      navigate('/returns');
      setTimeout(() => {
        navigate(redirectTo);
      }, 100);
    }, 100);
    // Commented out 6/01/2022
    // By John Bacabac - SPPHBI-29701
    */
    setTimeout(() => {
      navigate('/returns');
    }, 100);
    setShow(false);
    resetStates();
  };

  const handleShow = () => setShow(true);

  // set returns if url param present
  // eslint-disable-next-line consistent-return
  useEffect(async () => {
    const { id } = params;

    // need this condition to handle showing of modal on different cases
    if (id && toEdit <= 0 && !fromEdit) {
      // when going to /returns/{id}
      /* proceed */
    } else if (id && toEdit === parseInt(id, 10) && fromEdit) {
      // when going to /returns/{id}?edit=true
      /* proceed */
    } else return false;

    handleShow();
    const issReq = await axios.get(`/returns/items/${id}`);
    const issRes = await issReq.data;
    const {
      result: {
        id: returnItemId,
        count: quantity,
        delivery_item: deliveryItem,
        remarks,
        _return: returnResult,
      },
    } = issRes;

    const { supplier, description, id: returnId } = returnResult;
    setReturnState({
      returnItemId,
      returnId,
      dateReturned: moment(returnResult.created_at).format('YYYY-MM-DD'),
      deliveryItem: {
        value: deliveryItem.id,
        label: deliveryItem.asset_item.name,
      },
      description,
      status: returnResult.status,
      quantity, // qty of delivery item
      remarks,
      supplier: {
        value: supplier.id,
        label: supplier.name,
      },
    });
  }, [params.id]);

  const updateReturn = (value, attr) => {
    const cloned = { ...returnState };
    cloned[attr] = value;
    setReturnState(cloned);
  };

  const markComplete = () => {
    const { id } = params;
    axios.put(`/returns/${returnState?.returnId || ''}`, {
      status: 'completed',
    }).then(() => {
      fetchReturns();
      handleClose(`/returns/${id}`);
    });
  };

  const handleSubmit = async () => {
    const { id } = params;
    setLoading(true);

    const {
      dateReturned,
      deliveryItem,
      description,
      quantity,
      remarks,
      supplier,
    } = returnState;

    const method = id ? 'put' : 'post';

    // put/post Return
    const returnReq = await axios[method](`/returns/${returnState?.returnId || ''}`, {
      ...(dateReturned && { created_at: dateReturned }),
      description,
      supplier: supplier.value,
      spp_user: 1, // NOTE TO SELF EDIT THIS PLACEHOLDER ONCE SPP USER FE DONE
    });
    const returnRes = await returnReq.data;

    const toSend = {
      count: quantity,
      _return: returnState.returnId || returnRes.id,
      delivery_item: deliveryItem.value,
      remarks,
    };

    // put/post Return Item
    const returnItemReq = await axios[method](
      `/returns/items/${id || ''}`,
      method === 'put' ? toSend : [toSend],
    );

    // this returns an array not single object
    const returnItemRes = await returnItemReq.data;

    handleClose(`/returns/${id || returnItemRes[0].id}${!params?.id ? '?create-success=true' : '?edit-success=true'}`);
    fetchReturns();
    resetStates();
    setLoading(false);
  };

  const disableButton = (
    !returnState.supplier.value
      || !returnState.deliveryItem.value
      || returnState.quantity <= 0
      || loading
  );

  let spanMessage = '';
  if (editSuccess) spanMessage = 'Edited Successfully';
  else if (createSuccess) spanMessage = 'Created Successfully';

  return (
    <div className="addReturnsModal">
      { toEdit <= -1
        ? (
          <Button onClick={handleShow}>
            <Plus /> Add Returns
          </Button>
        )
        : (
          <div className="actionButton--edit" onClick={handleShow} aria-hidden="true">
            <Edit />
          </div>
        )}
      {/* div modal rendered outside the parent container */}
      <Modal
        className="addReturnsModal"
        show={show}
        onHide={() => {
          handleClose();
        }}
        scrollable
      >
        <Modal.Header>
          <Modal.Title>
            <span>
              {!params?.id ? 'Add' : 'Edit'} Returns Report &nbsp;
              <span className="success">
                { spanMessage }
              </span>
            </span>
            {params?.id
              && (
              <Button
                onClick={markComplete}
                disabled={returnState.status === 'completed'}
              >
                {
                  returnState.status === 'completed' ? 'Completed' : 'Mark Complete'
                }
              </Button>
              )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Container>
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="returnsDateDelivered">
                    <Form.Label>Date Returned</Form.Label>
                    <Form.Control
                      type="date"
                      id="startDate"
                      onChange={({ target }) => {
                        updateReturn(target.value, 'dateReturned');
                      }}
                      value={returnState.dateReturned}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Form.Label>Supplier</Form.Label>
                  <Select
                    options={suppliers}
                    onChange={(value) => {
                      updateReturn(value, 'supplier');
                    }}
                    value={returnState.supplier}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="returnsDescription">
                    <Form.Label>Choose Delivered Item</Form.Label>
                    <FilterSearch
                      handleChange={(value, i, toUpdate) => {
                        const clonedValue = { ...value };
                        updateReturn(clonedValue, toUpdate);
                      }}
                      endpoint="/deliveries/items/"
                      toUpdate="deliveryItem"
                      selected={returnState.deliveryItem}
                      filters={
                        { supplier: returnState.supplier }
                      }
                      key={returnState.supplier.value}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="returnsDescription">
                    <Form.Label>Material Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      onChange={({ target }) => {
                        updateReturn(target.value, 'description');
                      }}
                      value={returnState.description}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="returnsQty">
                    <Form.Label>Quantity</Form.Label>
                    <Form.Control
                      type="number"
                      onChange={({ target }) => {
                        updateReturn(target.value, 'quantity');
                      }}
                      value={returnState.quantity}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="returnsRemarks">
                    <Form.Label>Remarks</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={({ target }) => {
                        updateReturn(target.value, 'remarks');
                      }}
                      value={returnState.remarks}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="white"
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={disableButton}
          >
            { loading ? <Loading /> : 'Submit' }
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

AddReturnsModal.propTypes = {
  toEdit: PropType.number,
  fetchReturns: PropType.func.isRequired,
};

AddReturnsModal.defaultProps = {
  toEdit: -1,
};

export default AddReturnsModal;
