/* eslint-disable no-nested-ternary */
import React from 'react';
import { Pagination } from 'react-bootstrap';
import PropTypes from 'prop-types';

function CustomPagination({ page, setPage }) {
  const { current, total } = page;

  const handlePage = (e) => {
    const { target } = e;
    let toPage = target.innerText;

    if ((toPage.toLowerCase().includes('previous')
      || toPage.includes('‹'))
      && current > 1) {
      toPage = current - 1;
    } else if ((toPage.toLowerCase().includes('next')
      || toPage.includes('›'))
      && current < total) {
      toPage = current + 1;
    }

    toPage = parseInt(toPage, 10);
    const cloned = { ...page };
    cloned.current = toPage || current;

    setPage(cloned);
  };

  const nearEnd = total - current <= 3;
  const inMiddle = current > 2;

  const renderMiddleOptions = () => {
    return (
      <>
        {/* Middle Indexes */}
        <Pagination.Ellipsis disabled />
        <Pagination.Item
          onClick={handlePage}
          active={nearEnd ? (total - current === 3) : !nearEnd}
          style={{
            display: (total - 2 > 0 && (current > 5 || total > 5)) ? '' : 'none',
          }}
        >
          { nearEnd ? total - 3 : current }
        </Pagination.Item>
        <Pagination.Item
          onClick={handlePage}
          active={nearEnd ? (total - current === 2) : false}
          style={{
            display: total - 2 > 0 ? '' : 'none',
          }}
        >
          { nearEnd ? total - 2 : current + 1 }
        </Pagination.Item>
        <Pagination.Ellipsis disabled />
      </>
    );
  };

  return (
    <div className="customPagination">
      <Pagination>
        <Pagination.Prev
          onClick={handlePage}
          disabled={current <= 1}
        />
        {/* First Index */}
        <Pagination.Item
          onClick={handlePage}
          active={current === 1}
        >
          1
        </Pagination.Item>
        {/* Second Index */}
        <Pagination.Item
          onClick={handlePage}
          active={current === 2}
          style={{
            display: total > 1 ? '' : 'none',
          }}
        >
          2
        </Pagination.Item>

        { (inMiddle && total > 4)
          ? renderMiddleOptions()
          : (total > 2)
            ? <Pagination.Ellipsis disabled />
            : <div />}

        {/* Second to Last Index */}
        <Pagination.Item
          onClick={handlePage}
          active={current === total - 1}
          style={{
            display: total <= 3 ? 'none' : '',
          }}
        >
          { total - 1 }
        </Pagination.Item>
        {/* Last Index */}
        <Pagination.Item
          onClick={handlePage}
          active={current === total}
          style={{
            display: total <= 2 ? 'none' : '',
          }}
        >
          { total }
        </Pagination.Item>
        <Pagination.Next
          onClick={handlePage}
          disabled={current >= total}
        />
      </Pagination>
    </div>
  );
}

export default CustomPagination;

CustomPagination.propTypes = {
  page: PropTypes.object.isRequired,
  setPage: PropTypes.func.isRequired,
};
