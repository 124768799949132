import React, { useContext } from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

import AppContext from '../AppContext';
import DeleteModal from './DeleteModal';

const MerchantList = ({ toSearch }) => {
  const { merchants, refetchMerchants } = useContext(AppContext);

  const merchantsToReturn = toSearch !== ''
    ? merchants.filter((merchant) => {
      return merchant.name.toLowerCase().includes(toSearch);
    })
    : merchants;

  return (
    <section className="supplierList">
      <Table>
        <thead>
          <tr>
            <td> Merchant Name</td>
          </tr>
        </thead>
        <tbody>
          {merchantsToReturn.map((merchant) => (
            <tr>
              <td className="supplier-delete">
                <div>
                  <DeleteModal
                    endpoint="/issuances/merchants/"
                    id={merchant.id}
                    callback={refetchMerchants}
                  />
                </div>
              </td>
              <td className="supplier-name">
                <span>{merchant.name}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </section>
  );
};

MerchantList.propTypes = {
  toSearch: PropTypes.string.isRequired,
};

export default MerchantList;
