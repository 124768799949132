import React, { useContext } from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

import AppContext from '../AppContext';
import DeleteModal from './DeleteModal';

const SupplierList = ({ toSearch }) => {
  const { suppliers, refetchSuppliers } = useContext(AppContext);

  const suppliersToReturn = toSearch !== ''
    ? suppliers.filter((supplier) => {
      return supplier.name.toLowerCase().includes(toSearch);
    })
    : suppliers;

  return (
    <section className="supplierList">
      <Table>
        <thead>
          <tr>
            <td> Supplier Name</td>
          </tr>
        </thead>
        <tbody>
          {suppliersToReturn.map((supplier) => (
            <tr>
              <td className="supplier-delete">
                <div>
                  <DeleteModal
                    endpoint="/deliveries/suppliers/"
                    id={supplier.id}
                    callback={refetchSuppliers}
                  />
                </div>
              </td>
              <td className="supplier-name">
                <span>{supplier.name}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </section>
  );
};

SupplierList.propTypes = {
  toSearch: PropTypes.string.isRequired,
};

export default SupplierList;
