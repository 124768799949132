/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useState, useEffect, useRef, useContext,
} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import AddIssuanceModal from './AddIssuanceModal';

import IssuanceCard from './reusables/IssuanceCard';
import DateFilter from './reusables/DateFilter';
import CustomPagination from './reusables/CustomPagination';
import ManageMerchants from './ManageMerchantsModal';
import Loading from './reusables/Loading';
import FileDownload from './FileDownload';
import { ReactComponent as Search } from '../assets/images/general/Search.svg';
import AppContext from './AppContext';
import { statuses } from '../assets/helpers';

function IssuanceCardList({
  issuances,
  fetchIssuances,
  setMerchantFilter,
}) {
  const titleList = issuances.map((item) => (
    <IssuanceCard
      item={item}
      fetchIssuances={fetchIssuances}
      setMerchantFilter={setMerchantFilter}
    />
  ));
  return (
    <div className="inventoryCard__container">{titleList}</div>
  );
}

const Issuance = () => {
  const { merchants: merchs } = useContext(AppContext);
  const merchants = merchs.map((merch) => {
    return {
      value: merch.id,
      label: merch.name,
    };
  });

  const [toSearch, setToSearch] = useState('');
  // will be the merchant object mismo
  const [merchantFilter, setMerchantFilter] = useState({});
  const [filter, setFilter] = useState({
    startDate: null,
    endDate: null,
  });
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState({
    current: 1,
    total: 10,
  });
  const [issuances, setIssuances] = useState([]);
  const [show, setShow] = useState(false);

  const timeoutRef = useRef(null);

  const handleSetFilter = (key, value) => {
    const cloned = { ...filter };
    cloned[key] = value;
    setFilter(cloned);
  };

  const fetchIssuances = async () => {
    setLoading(true);
    const { startDate, endDate } = filter;
    const req = await axios.get('/issuances/', {
      params: {
        page: page.current,
        start_date: startDate,
        end_date: endDate,
        merchant_id: merchantFilter?.id,
        ...(merchantFilter?.filterStatus && { status: merchantFilter.filterStatus }),
        ...(toSearch !== '' && { description: toSearch }),
      },
    });
    const res = await req.data;
    const { result, total_pages: totalPages } = res;
    setIssuances(result);

    const cloned = { ...page };
    cloned.total = totalPages;
    setPage(cloned);
    setLoading(false);
  };

  useEffect(() => {
    fetchIssuances();
  }, [
    page.current,
    filter, merchantFilter?.id,
    merchantFilter?.filterStatus,
  ]);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      fetchIssuances();
    }, 200);
  }, [toSearch]);

  const showTable = () => {
    if (loading) {
      return <Loading />;
    }
    if (issuances[0]) {
      return (
        <div>
          <IssuanceCardList
            issuances={issuances}
            fetchIssuances={fetchIssuances}
            setMerchantFilter={setMerchantFilter}
          />
          <CustomPagination
            page={page}
            setPage={setPage}
          />
        </div>
      );
    }
    return (
      <div className="issuance__merchantLabel">
        <p>
          No Results
        </p>
      </div>
    );
  };

  const handleClose = () => setShow(false);
  const exportURL = '/issuances/export/';

  const searchStyle = {
    option: (provided, state) => ({
      ...provided,
      background: 'white',
      color: state.isSelected ? 'blue' : 'black',
    }),
    control: (provided) => ({
      ...provided,
      background: 'white',
      border: 'none',
      minWidth: '200px',
    }),
  };

  const { filterStatus } = merchantFilter;
  return (
    <div className="issuance">
      <div className="customButton">
        <DateFilter
          handleOnChange={handleSetFilter}
          startDate={filter.startDate}
          endDate={filter.endDate}
        />
        <FileDownload url={exportURL} />
        <AddIssuanceModal
          show={show}
          onHide={handleClose}
          fetchIssuances={fetchIssuances}
        />
        <ManageMerchants show={show} onHide={handleClose} />
      </div>
      <div className="customButton">
        { (merchantFilter.id || filterStatus) ? (
          <p className="issuance__merchantLabel">
            Viewing <b>{filterStatus || 'all'}</b> issuances of merchant:
            &nbsp;<b>{ merchantFilter.name || 'all' }</b>
            <span
              onClick={() => {
                setMerchantFilter({});
              }}
              role="button"
              tabIndex={0}
            >
              X
            </span>
          </p>
        ) : <p />}
        <div>
          <Select
            className="issuance__merchant-filter"
            options={Object.keys(statuses).map((statusKey) => {
              const label = statuses[statusKey];
              return {
                label,
                value: statusKey,
              };
            })}
            placeholder="Status"
            styles={searchStyle}
            onChange={({ value }) => {
              setMerchantFilter((prevState) => ({
                ...prevState,
                filterStatus: value,
              }));
            }}
            value={filterStatus ? {
              value: filterStatus,
              label: `${filterStatus[0].toUpperCase()}${filterStatus.slice(1, filterStatus.length)}`,
            } : null}
          />
          <Select
            className="issuance__merchant-filter"
            options={merchants}
            placeholder="Select Merchant"
            styles={searchStyle}
            onChange={({ value, label }) => {
              setMerchantFilter((prevState) => ({
                ...prevState,
                id: value,
                name: label,
              }));
            }}
            value={merchantFilter.id ? {
              value: merchantFilter.id,
              label: merchantFilter.name,
            } : null}
          />
          <div className="issuance__search">
            <Form.Control
              type="text"
              placeholder="Search by Material Description"
              onChange={({ target }) => {
                setToSearch(target.value);
              }}
            />
            <Search />
          </div>
        </div>
      </div>
      { showTable() }
    </div>
  );
};

export default Issuance;

IssuanceCardList.propTypes = {
  issuances: PropTypes.array.isRequired,
  fetchIssuances: PropTypes.func.isRequired,
  setMerchantFilter: PropTypes.func.isRequired,
};
