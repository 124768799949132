import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DeliveryCard from './reusables/DeliveryCard';
import DateFilter from './reusables/DateFilter';
import CustomPagination from './reusables/CustomPagination';

import AddDeliveryModal from './AddDeliveryModal';
import ManageSuppliersModal from './ManageSuppliersModal';
import Loading from './reusables/Loading';
import FileDownload from './FileDownload';

const Delivery = () => {
  const [filter, setFilter] = useState({
    startDate: null,
    endDate: null,
  });
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState({
    current: 1,
    total: 10,
  });
  const [deliveries, setDeliveries] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleSetFilter = (key, value) => {
    const cloned = { ...filter };
    cloned[key] = value;
    setFilter(cloned);
  };

  const fetchDeliveries = async () => {
    setLoading(true);
    const { startDate, endDate } = filter;
    const req = await axios.get('/deliveries/', {
      params: {
        page: page.current,
        start_date: startDate,
        end_date: endDate,
      },
    });
    const res = await req.data;
    const { result, total_pages: totalPages } = res;
    setDeliveries(result);

    const cloned = { ...page };
    cloned.total = totalPages;
    setPage(cloned);
    setLoading(false);
  };

  useEffect(() => {
    fetchDeliveries();
  }, [page.current, filter]);

  const showTable = () => {
    if (loading) {
      return <Loading />;
    }
    if (deliveries[0]) {
      return (
        <div>
          {
            deliveries.map((delivery) => (
              <DeliveryCard
                item={delivery}
                fetchDeliveries={fetchDeliveries}
              />
            ))
          }
          <CustomPagination
            page={page}
            setPage={setPage}
          />
        </div>
      );
    }
    return (
      <div style={{ textAlignVertical: 'center', textAlign: 'center' }} className="issuance__merchantLabel">
        <p>
          No Results
        </p>
      </div>
    );
  };

  const exportURL = '/deliveries/export/';
  return (
    <div className="delivery">
      <div className="customButton">
        <DateFilter
          handleOnChange={handleSetFilter}
          startDate={filter.startDate}
          endDate={filter.endDate}
        />
        <FileDownload url={exportURL} />
        <AddDeliveryModal
          show={show}
          onHide={handleClose}
          fetchDeliveries={fetchDeliveries}
        />
        <ManageSuppliersModal show={show} onHide={handleClose} />
      </div>
      { showTable() }
    </div>
  );
};

export default Delivery;
