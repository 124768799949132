import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import AddReturnsModal from '../AddReturnsModal';
import Delete from './DeleteModal';

const List = ({
  data,
  type,
  deleteEndpoint,
  deleteEndpoints,
  fetchItems,
}) => {
  const navigate = useNavigate();

  const headers = data[0] ? Object.keys(data[0]) : [];
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  return (
    <section className="list">
      <Table>
        <thead>
          <tr>
            {
              headers.map((header) => (<th>{ header }</th>))
            }
          </tr>
        </thead>
        <tbody>
          {
          data.map((dataFoo) => (
            <tr>
              {
                headers.map((header) => (
                  <td>{dataFoo[header]}</td>
                ))
              }
              <td>
                <div className="actionButton justify-content-center">
                  <div className="actionButton">
                    <div
                      role="button"
                      tabIndex={-1}
                      onClick={() => {
                        navigate(`/${type}/${dataFoo.ID}?edit=true`);
                      }}
                    >
                      <AddReturnsModal
                        show={show}
                        onHide={handleClose}
                        toEdit={dataFoo.ID}
                        fetchReturns={fetchItems}
                      />
                    </div>
                    { /* if you want to delete more than one type */ }
                    <Delete
                      endpoint={deleteEndpoint}
                      endpoints={deleteEndpoints}
                      id={dataFoo.ID}
                      fetchItems={fetchItems}
                    />
                  </div>
                </div>
              </td>
            </tr>
          ))
          }
        </tbody>
      </Table>
    </section>
  );
};

export default List;

List.propTypes = {
  data: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  deleteEndpoint: PropTypes.string,
  deleteEndpoints: PropTypes.array,
  fetchItems: PropTypes.func.isRequired,
};

List.defaultProps = {
  deleteEndpoints: [],
  deleteEndpoint: '',
};
