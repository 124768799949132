import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import AppContext from './AppContext';
import InventoryCard from './reusables/InventoryCard';
import AddInventoryModal from './AddInventoryModal';
import FileDownload from './FileDownload';
// import Loading from './reusables/Loading';

function InventoryCardList({ categories }) {
  const titleList = categories.map((category) => (
    <InventoryCard
      category={category}
    />
  ));
  return (
    <div className="inventoryCard__container">{titleList}</div>
  );
}

InventoryCardList.propTypes = {
  categories: PropTypes.array,
};

InventoryCardList.defaultProps = {
  categories: [],
};

const Inventory = () => {
  const [show, setShow] = useState(false);
  const { categories } = useContext(AppContext);
  const handleClose = () => setShow(false);
  const exportURL = '/assets/export/';

  const showTables = () => {
    if (categories?.length > 0) {
      return (
        <InventoryCardList
          categories={categories}
        />
      );
    }
    return (
      <div className="issuance__merchantLabel">
        <p>
          No Results
        </p>
      </div>
    );
  };

  return (
    <div className="inventory">
      <div className="customButton">
        <FileDownload url={exportURL} />
        <AddInventoryModal show={show} onHide={handleClose} />
      </div>
      { showTables() }
    </div>
  );
};

export default Inventory;
