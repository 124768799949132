import {
  React, useState, useEffect, useContext,
} from 'react';
import {
  useParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {
  Button, Modal, Container, Row, Col, Form,
} from 'react-bootstrap';
import PropType from 'prop-types';
import axios from 'axios';
import Select from 'react-select';
import moment from 'moment';

import AppContext from './AppContext';
import FilterSearch from './reusables/FilterSearch';
import Loading from './reusables/Loading';

import { ReactComponent as Plus } from '../assets/images/general/plus.svg';
import { ReactComponent as Edit } from '../assets/images/general/Edit.svg';
import { ReactComponent as Delete } from '../assets/images/general/Delete.svg';

function AddDeliveryModal({ toEdit, fetchDeliveries }) {
  const [searchParams] = useSearchParams();
  const fromEdit = searchParams.get('edit') === 'true';
  const createSuccess = searchParams.get('create-success') === 'true';
  const editSuccess = searchParams.get('edit-success') === 'true';

  const params = useParams();
  const navigate = useNavigate();

  const { suppliers: supps } = useContext(AppContext);
  const suppliers = supps.map((supp) => {
    return {
      value: supp.id,
      label: supp.name,
    };
  });

  const iniDeliveryState = {
    dateDelivered: moment().format('YYYY-MM-DD'),
    dateCount: '',
    supplier: { value: 0, label: '' },
    description: '',
    // NOTE TO SELF, REVERT VALUE TO 0 ONCE DROPDOWN IS DONE
    sppUser: { value: 1, label: '' },
  };

  // saved asset items
  const [inputList, setInputList] = useState([{ asset: {}, count: 0 }]);
  const [itemsToDelete, setItemsToDelete] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [delivery, setDelivery] = useState(iniDeliveryState);

  const resetState = () => {
    setInputList([{ asset: {}, count: 0 }]);
    setDelivery(iniDeliveryState);
  };

  const handleClose = (/* redirectTo = '/delivery' */) => {
    /* setTimeout(() => {
      // redirect to deliveries first to trigger hook
      navigate('/delivery');
      setTimeout(() => {
        navigate(redirectTo);
      }, 100);
    }, 100);
    // Commented out 6/01/2022
    // By John Bacabac - SPPHBI-29701
    */
    setTimeout(() => {
      navigate('/delivery');
    }, 100);
    setShow(false);
    resetState();
  };
  const handleShow = () => setShow(true);

  // set delivery if url param present
  // eslint-disable-next-line consistent-return
  useEffect(async () => {
    const { id } = params;

    // need this condition to handle showing of modal on different cases
    if (id && toEdit <= 0 && !fromEdit) {
      // when going to /returns/{id}
      /* proceed */
    } else if (id && toEdit === parseInt(id, 10) && fromEdit) {
      // when going to /returns/{id}?edit=true
      /* proceed */
    } else return false;

    handleShow();
    const issReq = await axios.get(`/deliveries/${id}`);
    const issRes = await issReq.data;
    const {
      result: {
        id: delId,
        created_at: createdAt,
        description,
        supplier,
        spp_user: sppUser,
        count_date_time: dateCount,
        deliveryitem_set: deliveryItemSet,
      },
    } = issRes;

    setDelivery({
      dateDelivered: moment(createdAt).format('YYYY-MM-DD'),
      dateCount: moment(dateCount).format('YYYY-MM-DD'),
      supplier: { value: supplier.id, label: supplier.name },
      description,
      sppUser: { value: sppUser?.id, label: sppUser?.name },
      id: delId,
    });

    setInputList(deliveryItemSet.map((delItem) => {
      return {
        asset: {
          value: delItem.asset_item.id,
          label: delItem.asset_item.name,
          resultItem: { delItemId: delItem.id, ...delItem }, // need this for updating
        },
        count: delItem.count,
      };
    }));
  }, [params.id]);

  const updateDelivery = (value, attr) => {
    const cloned = { ...delivery };
    cloned[attr] = value;
    setDelivery(cloned);
  };

  const handleSubmit = async () => {
    const { id } = params;
    setLoading(true);

    const {
      dateCount,
      supplier,
      description,
      sppUser,
      dateDelivered,
    } = delivery;

    const itemsSet = inputList.map((input) => {
      const { asset, count } = input;
      return {
        asset_item: asset.value,
        count,
      };
    });

    const method = id ? 'put' : 'post';
    const delReq = await axios[method](`/deliveries/${id || ''}`, {
      ...(dateDelivered && { created_at: dateDelivered }),
      supplier: supplier.value,
      spp_user: sppUser.value,
      count_date_time: dateCount,
      description,
      ...(method === 'post' && { deliveryitem_set: itemsSet }),
    });

    const delRes = delReq.data;

    // update issuance items
    if (method === 'put') {
      const promisesPUT = [];
      const promisesDELETE = [];

      const itemsToPost = [];
      inputList.forEach(({
        asset: { resultItem, value: assetItemId },
        count,
      }) => {
        const deliveryItemId = resultItem?.delItemId;

        // PUT if item contains issuance id (meaning it's already saved in db)
        if (deliveryItemId) {
          promisesPUT.push(axios.put(`/deliveries/items/${deliveryItemId}`, {
            asset_item: assetItemId,
            count,
          }));
        } else {
          // POST if item doesn't have issuance id yet
          itemsToPost.push({
            delivery: delivery.id,
            asset_item: assetItemId,
            count,
          });
        }
      });

      // DELETE Delivery Items
      if (itemsToDelete?.length > 0) {
        itemsToDelete.forEach((itemToDelete) => {
          const { resultItem } = itemToDelete.asset;
          if (resultItem) {
            promisesDELETE.push(axios.delete(`/deliveries/items/${resultItem?.delItemId}`));
          }
        });
      }

      try {
        await Promise.all(promisesDELETE);
        await Promise.all(promisesPUT);
        if (itemsToPost.length > 0) {
          await axios.post('/deliveries/items/', itemsToPost);
        }
      } catch (e) {
        alert(e);
      }
    }

    resetState();
    setLoading(false);
    handleClose(`/delivery/${delRes.id}${!params?.id ? '?create-success=true' : '?edit-success=true'}`);
    fetchDeliveries();
  };

  /** handle input change
   * e can either be a dom object from the count input or an obect from the search
   */
  const handleInputChange = (e, index, attr) => {
    let value = e.target?.value !== undefined
      ? e.target?.value
      : e;
    const list = [...inputList];

    if (attr === 'asset') {
      const resultItem = list[index].asset?.resultItem;
      if (resultItem) value.resultItem = resultItem;
    } else if (attr === 'count') {
      value = parseInt(value, 10);
    }

    list[index][attr] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    const delClone = [...itemsToDelete];

    setItemsToDelete([...delClone, list[index]]);
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { asset: {}, count: 0 }]);
  };

  const disableButton = (
    delivery.dateCount === ''
      || inputList.length <= 0
      || !(inputList.every((item) => item.asset.value && item.count > 0))
      || delivery.description.trim() === ''
      || !delivery.supplier.value
      || loading
  );

  let spanMessage = '';
  if (editSuccess) spanMessage = 'Edited Successfully';
  else if (createSuccess) spanMessage = 'Created Successfully';

  return (
    <div className="addDeliveryModal">
      { toEdit <= -1
        ? (
          <Button onClick={handleShow}>
            {' '}
            <Plus /> Add Delivery{' '}
          </Button>
        )
        : (
          <div className="actionButton--edit" onClick={handleShow} aria-hidden="true">
            <Edit />
          </div>
        )}
      <Modal show={show} onHide={handleClose} scrollable>
        <Modal.Header>
          <Modal.Title>
            {!params?.id ? 'Add' : 'Edit'} Delivery Report &nbsp;
            <span className="success">
              { spanMessage }
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Container>
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="deliveryDate">
                    <Form.Label>Date Delivered</Form.Label>
                    <Form.Control
                      type="date"
                      id="startDate"
                      onChange={({ target }) => {
                        updateDelivery(target.value, 'dateDelivered');
                      }}
                      value={delivery.dateDelivered}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="deliveryCount">
                    <Form.Label>Date of Count</Form.Label>
                    <Form.Control
                      type="date"
                      id="startDate"
                      onChange={({ target }) => {
                        updateDelivery(target.value, 'dateCount');
                      }}
                      value={delivery.dateCount}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="deliverySupplier">
                    <Form.Label>Supplier Name</Form.Label>
                    <Select
                      options={suppliers}
                      onChange={(e) => {
                        updateDelivery(e, 'supplier');
                      }}
                      value={delivery.supplier}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                {inputList.map((x, i) => {
                  return (
                    <>
                      <Col xs={7} className="mb-3">
                        <Form.Group controlId="material">
                          <Form.Label>Material</Form.Label>
                          <FilterSearch
                            handleChange={handleInputChange}
                            index={i}
                            selected={inputList[i].asset}
                          />
                        </Form.Group>
                      </Col>
                      <Col lassName="mb-3">
                        <Form.Group controlId="deliveryCount">
                          <Form.Label>Actual Count</Form.Label>
                          <Form.Control
                            type="number"
                            id="actualCount"
                            onChange={(e) => handleInputChange(e, i, 'count')}
                            value={inputList[i].count}
                          />
                        </Form.Group>
                      </Col>
                      <Col lassName="mb-3">
                        <Form.Label> </Form.Label>
                        <div className="actionButton">
                          {inputList.length - 1 === i && (
                          <div
                            className="actionButton--add mr-5"
                            onClick={handleAddClick}
                            aria-hidden="true"
                          >
                            <Plus />
                          </div>
                          )}
                          {inputList.length !== 1 && (
                          <div
                            className="actionButton--delete"
                            onClick={() => handleRemoveClick(i)}
                            aria-hidden="true"
                          >
                            <Delete />
                          </div>
                          )}
                        </div>
                      </Col>
                    </>
                  );
                })}
              </Row>
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="deliveryDescription">
                    <Form.Label>Material Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      onChange={({ target }) => {
                        updateDelivery(target.value, 'description');
                      }}
                      value={delivery.description}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="white"
            onClick={() => { handleClose(); }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={disableButton}
          >
            {loading ? <Loading /> : 'Submit'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

AddDeliveryModal.propTypes = {
  toEdit: PropType.number,
  fetchDeliveries: PropType.func.isRequired,
};

AddDeliveryModal.defaultProps = {
  toEdit: -1,
};

export default AddDeliveryModal;
