import axios from 'axios';

export async function fetchCategories() {
  const req = await axios.get('/assets/categories');
  const res = await req.data;

  return res.result;
}

export async function fetchSppUsers() {
  const req = await axios.get('/issuances/spp_users/');
  const res = await req.data;

  return res.result;
}

export async function fetchMerchants() {
  const req = await axios.get('/issuances/merchants/');
  const res = await req.data;

  return res.result;
}

export async function fetchSuppliers() {
  const req = await axios.get('/deliveries/suppliers/', {
    params: {
      limit: 100000,
    },
  });
  const res = await req.data;

  return res.result;
}

export const statuses = {
  pending: 'Pending',
  completed: 'Completed',
  'in-transit': 'In-Transit',
  deployed: 'Deployed',
  rescheduled: 'Rescheduled',
  cancelled: 'Cancelled',
  'on-hold': 'On-Hold',
  'failed deployment': 'Failed Deployment',
};
