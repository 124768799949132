// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAbaiZr9kz0qM4bLPzRybTCH2MacL0MuZw',
  authDomain: 'spp-inventory-347908.firebaseapp.com',
  projectId: 'spp-inventory-347908',
  storageBucket: 'spp-inventory-347908.appspot.com',
  messagingSenderId: '495208689469',
  appId: '1:495208689469:web:fd4111ecc0b5fbc10d5f54',
  measurementId: 'G-D7SZXJKFWT',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;
