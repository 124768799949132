/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import moment from 'moment';

const DateFilter = ({
  handleOnChange,
  startDate,
  endDate,
}) => {
  return (
    <section className="dateFilter">
      <label htmlFor="startDate">From</label>
      <Form.Control
        type="date"
        id="startDate"
        placeholder="Start Date"
        max={endDate}
        onChange={({ target }) => {
          handleOnChange('startDate', target.value);
        }}
      />
      <label htmlFor="startDate">To</label>
      <Form.Control
        type="date"
        id="endDate"
        placeholder="End Date"
        min={startDate}
        onChange={({ target }) => {
          handleOnChange('endDate', target.value);
        }}
      />
      { moment(startDate) > moment(endDate)
        && <span>Invalid Range</span>}
    </section>
  );
};

DateFilter.propTypes = {
  handleOnChange: PropTypes.func.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};

DateFilter.defaultProps = {
  startDate: undefined,
  endDate: undefined,
};

export default DateFilter;
