import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import axios from 'axios';

const FilterSearch = ({
  endpoint,
  handleChange,
  selected,
  index,
  valuesToHide, // array of values to hide from dropdown
  toUpdate,
  filters,
}) => {
  const fetchData = (inputValue = '', cb = () => {}) => {
    const params = { name: inputValue, ...filters };
    axios.get(endpoint, {
      params,
    }).then((res) => {
      const { data } = res;
      let toSet = data.result.map((resultItem) => {
        const { id, name } = resultItem;
        let toReturn = {
          value: id,
          label: name,
          count: resultItem.actual_count,
        };
        if (endpoint === '/deliveries/items/') {
          const {
            asset_item: assetItem,
            id: deliveryItemId,
          } = resultItem;
          toReturn = {
            value: deliveryItemId,
            label: `${assetItem.name} || ${resultItem.created_at}`,
          };
        }
        return toReturn;
      });

      toSet = toSet.filter((toSetItem) => !valuesToHide.includes(toSetItem.value));
      cb(toSet);
    });
  };

  const loadOptions = (inputValue, callback) => {
    fetchData(inputValue, (itemsToSet) => {
      callback(itemsToSet);
    });
  };

  return (
    <div className="filterSearch">
      <AsyncSelect
        cacheOptions
        loadOptions={loadOptions}
        defaultOptions
        onChange={(value) => {
          handleChange(value, index, toUpdate);
        }}
        value={selected}
      />
    </div>
  );
};

FilterSearch.propTypes = {
  endpoint: PropTypes.string,
  index: PropTypes.number,
  toUpdate: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  selected: PropTypes.object.isRequired,
  filters: PropTypes.object,
  valuesToHide: PropTypes.array,
};

FilterSearch.defaultProps = {
  endpoint: '/assets/',
  index: -1,
  toUpdate: 'asset',
  filters: {},
  valuesToHide: [],
};

export default FilterSearch;
