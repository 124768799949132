import {
  React, useEffect, useState, useContext,
} from 'react';
import {
  Button, Modal, Container, Row, Form,
} from 'react-bootstrap';
import axios from 'axios';

import AppContext from './AppContext';
import SupplierList from './reusables/SupplierList';
import { ReactComponent as Plus } from '../assets/images/general/plus.svg';
import { ReactComponent as Search } from '../assets/images/general/Search.svg';

const ManageSuppliersModal = () => {
  const { refetchSuppliers } = useContext(AppContext);
  const [toSearch, setToSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [supplierName, setSupplierName] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (show) { setSupplierName(''); }
  }, [show]);

  const handleSubmit = () => {
    setLoading(true);
    axios.post('/deliveries/suppliers/', {
      name: supplierName,
    }).then(() => {
      setSupplierName('');
      setLoading(false);
      setToSearch('');
      refetchSuppliers();
    });
  };

  return (
    <div className="manageSuppliers">
      <Button onClick={handleShow} className="btn-outline">
        Manage Suppliers{' '}
      </Button>
      <Modal show={show} onHide={handleClose} scrollable>
        <Modal.Header>
          <Modal.Title>Manage Suppliers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Container>
              <Row className="mb-5 px-2">
                <Form.Control
                  type="text"
                  placeholder="Search"
                  onChange={({ target }) => {
                    setToSearch(target.value);
                  }}
                />
                <Search />
              </Row>
              <Row className="mb-3">
                <div className="d-flex justify-content-between">
                  <div className="mr-xs form__input-container">
                    {' '}
                    <Form.Control
                      type="text"
                      placeholder="Supplier Name"
                      onChange={({ target }) => {
                        setSupplierName(target.value);
                      }}
                      value={supplierName}
                    />
                  </div>
                  <Button
                    onClick={handleSubmit}
                    disabled={loading || supplierName === ''}
                  >
                    <Plus /> &nbsp; Add New
                  </Button>
                </div>
              </Row>
              <SupplierList
                toSearch={toSearch.toLowerCase()}
              />
            </Container>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ManageSuppliersModal;
